<template>
</template>

<script>
import download from 'downloadjs'
import { get_file } from '../util/plantillas/pdfs'
import { tipos_archivos } from '../util/utils'

export default {
    data() {
        return {
            path: this.$route.query.path,
            param: this.$route.query
        }
    },
    async created() {

        await this.$store.dispatch('get_jarvis_token')

        let raiz = null
        if (this.param.hasOwnProperty('raiz') && this.param.raiz != null && this.param.raiz != undefined && this.param.raiz.toString().length > 0) raiz = this.param.raiz
        const extension = this.path.substring(this.path.lastIndexOf('.'), this.path.length).toLowerCase()
        const nombre = this.path.substring(this.path.lastIndexOf('/') + 1, this.path.length)
        const mime = tipos_archivos.find(a => a.extension == extension)
        if (mime) {
            this.$store.state.loading = true
            let file
            if (raiz == null || raiz == 1) file = await this.$store.dispatch('genericos/get_file_jarvis', {
                path: `/jarvis_2.0${this.path}`,
                tipo: mime.mime
            })
            else file = await get_file(`${this.path}`, mime.mime)
            download(file, nombre, mime.mime)
            this.$store.state.loading = false
        } else {
            this.$store.dispatch('show_snackbar', {
                text: 'Tipo de archivo no válido para el sistema',
                color: 'error'
            })
        }
    }
}
</script>